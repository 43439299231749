<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{ $route.name }}</h5>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" href="#/master-data/airport/create"
              >Tambah Data</b-btn
            >
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col cols="4">
              <form-group-input
                ref="filter-name"
                type="text"
                placeholder=""
                :need-label="true"
                label="Nama Airport "
                v-model="filter.name"
                id="filter-name"
              />
            </b-col>
            <b-col cols="3">
              <label class="form-label">&nbsp;</label><br />
              <b-btn type="submit" variant="primary">Cari</b-btn>
              <b-btn
                type="button"
                @click.prevent="clearForm"
                variant="warning"
                class="ml-3 text-white"
                >Clear</b-btn
              >
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table
      aksi-dot
      ref="dataTable"
      :delete-reason="false"
      :can-delete="true"
      :can-edit="true"
      :data-url="apiUrl.Airport"
      :data-columns="columns"
      :dataParams="dataParams"
      :defaultDataParams="this.filter"
    >
      <template slot="content_regency_id" slot-scope="defaultSlotScope">
        <span>{{ defaultSlotScope.colData.regency.name }}</span>
      </template>
      <template slot="content_price" slot-scope="defaultSlotScope">
        <span>{{ formatCurrency(defaultSlotScope.colData.price) }}</span>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
import { formatModelValue } from '@/_helpers'
import paginatedTable from "@/components/PaginatedTable.vue";
import formGroupInput from "@/components/FormGroupInput.vue";
import Airport from "@/models/Airport.js";

const airportModel = new Airport();

export default {
  components: {
    formGroupInput,
    paginatedTable,
  },
  data() {
    return {
      isLoading: false,
      apiUrl: {
        Airport: airportModel.endpoint,
      },
      columns: [
        {
          prop: "name",
          label: "Nama",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        {
          prop: "regency_id",
          label: "Kota",
          resizable: true,
          sortable: true,
          minWidth: 150,
        },
        {
          prop: "price",
          label: "Harga",
          resizable: true,
          sortable: true,
          align: 'right',
          minWidth: 150,
        },
        {
          prop: "is_active",
          label: "Status",
          isToggle: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          align: "right",
        },
      ],
      filter: {
        name:
          typeof this.$route.query.name !== "undefined"
            ? this.$route.query.name
            : null,
      },
      dataParams: ["name"],
    };
  },
  beforeMount() {
    this.$router.push({ query: { ...this.$route.query } }).catch((err) => {});
  },
  mounted() {},
  methods: {
    formatCurrency(value) {
      return formatModelValue("price", value);
    },
    clearForm() {
      this.$nextTick(() => {
        this.filter = {
          name: null,
        };
        setTimeout(() => {
          this.doFilter();
        }, 100);
      });
    },
    doFilter() {
      this.$refs.dataTable.doFilter(this.filter);
    },
  },
};
</script>
